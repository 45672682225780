// ** Redux Imports
import {combineReducers} from "redux"

// ** Reducers Imports
import general from "./general.js"
import auth from "./auth.js"
import navbar from "./navbar.js"
import layout from "./layout.js"
import profile from "./profile.js"
import occasions from "./occasions.js"
import service_providers from "./service_providers.js"
import service_providers_requests from "./service_providers_requests"
import orders from "./orders"
import products from "./products"
import packages from "./packages"
import subCategories from "./subCategories"
import organizer_products from "./organizer_products.js"

const rootReducer = combineReducers({
  general,
  navbar,
  layout,
  auth,
  profile,
  occasions,
  service_providers,
  service_providers_requests,
  orders,
  products,
  packages,
  subCategories,
  organizer_products
})

export default rootReducer
